(function ($) {
    $.fn.parallax = function () {
        var window_width = $(window).width();
        // Parallax Scripts
        return this.each(function() {
            var $this = $(this);

            function updateParallax(initial) {
                var container_height;
                if (window_width < 601) {
                    container_height = ($this.height() > 0) ? $this.height() : $this.children('img').height();
                }
                else {
                    container_height = ($this.height() > 0) ? $this.height() : 500;
                }
                var $img = $this.children('img').first();
                var img_height = $img.height();
                var parallax_dist = img_height - container_height;
                var bottom = $this.offset().top + container_height;
                var top = $this.offset().top;
                var scrollTop = $(window).scrollTop();
                var windowHeight = window.innerHeight;
                var windowBottom = scrollTop + windowHeight;
                var percentScrolled = (windowBottom - top) / (container_height + windowHeight);
                var parallax = Math.round((parallax_dist * percentScrolled));

                if (initial) {
                    $img.css('display', 'block');
                }
                if ((bottom > scrollTop) && (top < (scrollTop + windowHeight))) {
                    $img.css('transform', 'translate3D(-50%,' + parallax + 'px, 0)');
                }
            }

            // Wait for image load
            $this.children('img').one('load', function() {
                updateParallax(true);
            }).each(function() {
                if(this.complete) $(this).load();
            });

            $('.mdl-layout__content').scroll(function() {
                window_width = $('.mdl-layout__content').width();
                updateParallax(false);
            });

            $('.mdl-layout__content').resize(function() {
                window_width = $('.mdl-layout__content').width();
                updateParallax(false);
            });

        });
    };
}( jQuery ));